import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "display-6 mb-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_anp_message = _resolveComponent("anp-message")!
  const _component_va_switch = _resolveComponent("va-switch")!
  const _component_anp_label = _resolveComponent("anp-label")!
  const _component_va_select = _resolveComponent("va-select")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('common.notifications')), 1),
    _createVNode(_component_anp_message, { message: _ctx.message }, null, 8, ["message"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.resources, (resource) => {
      return (_openBlock(), _createElementBlock("div", {
        key: resource.resourceId,
        class: "mb-2"
      }, [
        _createVNode(_component_va_switch, {
          modelValue: _ctx.values[resource.resourceId].enabled,
          "onUpdate:modelValue": ($event: any) => ((_ctx.values[resource.resourceId].enabled) = $event),
          class: "mb-2",
          label: resource.title,
          "left-label": "",
          size: "small"
        }, null, 8, ["modelValue", "onUpdate:modelValue", "label"]),
        (_ctx.values[resource.resourceId].enabled)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_anp_label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('settings.frequency')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_va_select, {
                modelValue: _ctx.values[resource.resourceId].frequency,
                "onUpdate:modelValue": ($event: any) => ((_ctx.values[resource.resourceId].frequency) = $event),
                class: "mb-6",
                options: _ctx.frequencyOptions
              }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
            ], 64))
          : _createCommentVNode("", true),
        (
        _ctx.values[resource.resourceId].enabled &&
        _ctx.values[resource.resourceId].frequency.value == 1440
      )
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createVNode(_component_anp_label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('settings.send-time')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_va_select, {
                modelValue: _ctx.values[resource.resourceId].time,
                "onUpdate:modelValue": ($event: any) => ((_ctx.values[resource.resourceId].time) = $event),
                class: "mb-6",
                options: _ctx.timeOptions
              }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
            ], 64))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ], 64))
}