import { pick } from 'lodash';

import { COLLECTION_KINDS } from '@/config/enums';

import http from '../http';
import listOptions, { ListOptions } from '../listOptions';

export interface Collection {
  id: string;
  kind: COLLECTION_KINDS;
}

export interface CollectionListItem {
  id: string;
  originTitle: string;
  title: string;
}

interface CollectionList {
  items: CollectionListItem[];
  clipped: string[];
}

export interface Clipping {
  previousId: string;
}

export interface Clippings {
  items: Clipping[];
}

export interface StoreClippings {
  id?: string;
  action?: string;
  origin: string;
  items: string[];
}

const index = (): Promise<Collection[]> => {
  return http.get(`/collections`);
};

const items = (id: string, options: ListOptions): Promise<CollectionList> => {
  const params = listOptions(options);
  return http.get(`/collections/${id}/items${params}`);
};

const storeClippings = (item: StoreClippings): Promise<Clippings> => {
  return http.put(`/collections/${item.id}`, {
    ...pick(item, ['action', 'origin', 'items']),
  });
};

export default { index, items, storeClippings };
